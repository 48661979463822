import $ from 'jquery'

$(document).ready(function() {
  co_vc_contact_footer()
})

function co_vc_contact_footer() {
  $('.co_vc_contact_footer').each(function() {
    const form = $(this).find('.form-wrapper')
    const formHeading = form.find('.form-heading')
    const formTitle = formHeading.data('title')
    const formBody = form.find('.form-body')

    formBody.hide()

    formHeading.on('click', () => {
      if (form.hasClass('open')) {
        formBody.slideUp(500)
        formHeading.text(formTitle + ' öffnen')
        form.removeClass('open')
      } else {
        formBody.slideDown(500)
        formHeading.text(formTitle + ' schließen')
        form.addClass('open')
      }
    })
  })
}
