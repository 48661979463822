export default {
  init() {
    // JavaScript to be fired on all pages
    window.addEventListener('load', wrapButtonText)
    window.addEventListener('load', setVH)
    window.addEventListener('resize', setVH)
    init_smooth_scrolling_anchors()
    vcImageGallery()
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
}

const vcImageGallery = () => {
  const removeModal = () => {
    $('#wpb_gallery_modal').addClass('onhide')
    $('.wpb_gallery .isotope-item a').removeClass('active')
    setTimeout(() => {
      $('#wpb_gallery_modal').remove()
    }, 500)
  }

  $('.wpb_gallery .isotope-item a').on('click', function(e) {
    e.preventDefault()
    const imgLink = $(this).attr('href')
    if ($(this).hasClass('active')) {
      removeModal()
    } else {
      $(this).addClass('active')
      $('body').append(
        '<div id="wpb_gallery_modal"><div class="overlay"></div><div class="inner"><img alt="Modal Image" src="' +
          imgLink +
          '" /></div></div>'
      )
      $('#wpb_gallery_modal .overlay').on('click', removeModal)
    }
  })
}

const wrapButtonText = () => {
  const buttons = document.getElementsByClassName('btn')
  for (let i = 0; i < buttons.length; i++) {
    const el = buttons.item(i)
    const innerHtml = el.innerHTML
    el.innerHTML = `<span class="btn-inner">${innerHtml}</span>`
  }
}

const setVH = () => {
  const vh = window.innerHeight * 0.01
  const vw = document.documentElement.clientWidth * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
  document.documentElement.style.setProperty('--vw', `${vw}px`)
  document.documentElement.style.setProperty(
    '--scrollbar-width',
    window.innerWidth - document.documentElement.clientWidth + 'px'
  )
}

function init_smooth_scrolling_anchors() {
  $(document).on(
    'click',
    'a[href*="#"]:not([href="#"]):not([href*="#vc_images-carousel"]):not([href*="/brennstoffe"]):not(.dhsv_vc_anchor_menu a):not(.collapse_ac):not([data-vc-tabs]):not([data-vc-accordion])',
    function() {
      if (
        location.pathname.replace(/^\//, '') ===
          this.pathname.replace(/^\//, '') &&
        location.hostname === this.hostname
      ) {
        const targetOffset = -130
        let target = $(decodeURIComponent(this.hash))
        //var scrollTop = $(window).scrollTop()
        target = target.length
          ? target
          : $('[name="' + this.hash.slice(1) + '"]')
        if (target.length) {
          const target_pos = target.offset().top
          $('html,body').animate(
            {
              scrollTop: target_pos + targetOffset,
            },
            1000
          )
          return false
        }
      }
    }
  )
}
