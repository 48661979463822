import { createStore as _createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { responsiveStoreEnhancer } from 'redux-responsive'

import {
  middleware as routerMiddlware,
  enhancer as routerEnhancer,
} from './modules/router'

export default function createStore(initialState) {
  const middleware = [thunk, routerMiddlware]

  const devtoolsOptions = {
    actionsBlacklist: [],
    name: `base-theme-next`,
  }

  const remoteDevtoolsOptions = {}

  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(devtoolsOptions)
      : process.env.NODE_ENV !== 'production'
      ? require('remote-redux-devtools').composeWithDevTools({
          ...devtoolsOptions,
          ...remoteDevtoolsOptions,
        })
      : compose

  const finalCreateStore = composeEnhancers(
    routerEnhancer,
    responsiveStoreEnhancer,
    applyMiddleware(...middleware)
  )(_createStore)

  const reducer = require('./modules/reducer')
  const store = finalCreateStore(reducer.default, initialState)

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./modules/reducer', () => {
      store.replaceReducer(require('./modules/reducer').default)
    })
  }

  if (process.env.NODE_ENV !== 'production') {
    window.store = store
  }

  return store
}
